import { Sector } from './sector';
import { UnityType } from './unity-type';

export class Local {
    id: number;
    name: string;
    sectors: Array<Sector>;
    unity?: UnityType;

    initializeSectors(sectors: Array<Sector>){
        return sectors.map(sector => new Sector(
           sector.id,
           sector.name,
           sector.monitorables,
           this
        ));
    }

    constructor(
        id: number,
        name: string,
        sectors: Array<Sector>,
        unity: UnityType
    ){
        this.id = id;
        this.name = name;
        this.sectors = this.initializeSectors(sectors);
        this.unity = unity;
    }
}
