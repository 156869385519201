import { Component, OnInit, Input } from '@angular/core';
import { Alarm } from '../../types/alarm';
import { Monitor } from '../../types/monitor';
import { AcceptedDialogComponent, AcceptedDialogData } from '../accepted-dialog/accepted-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-event-table',
  templateUrl: './event-table.component.html',
  styleUrls: ['./event-table.component.sass']
})
export class EventTableComponent implements OnInit {


  @Input()
  alarms: Alarm[];

  @Input()
  monitor: Monitor;

  displayedColumns: string[] = ['level', 'hour', 'message', 'status', 'accepted'];
  dataSource: any[] = [{level: "", hour: "", message: "", status: "", accepted: ""}]

  constructor(
    public dialog: MatDialog,
  ) { }

  openAcceptedDialog(alarm: Alarm, monitor: Monitor){
    const dialogRef = this.dialog.open(AcceptedDialogComponent, {
      data: {
        alarm,
        monitor
      },
      panelClass: 'custom-accepted-modal'
    });
  }

  ngOnInit(): void {
  }

  padNumber(numberToPad: number): string{
      return numberToPad.toString().padStart(2,'0');
  }
}
