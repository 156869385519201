import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { publishReplay, refCount } from 'rxjs/operators';
import { Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  cachedConfig: Observable<any>;
  constructor(
    private httpClient: HttpClient,
  ) { }

  getAppConfig(): Observable<any> {
    if(!this.cachedConfig){
        this.cachedConfig = this.httpClient.get(environment.apiUrl + "/app-config").pipe(
          publishReplay(1),
          refCount()
        )
    }

    return this.cachedConfig;
  }
}
