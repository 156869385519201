import { Component, OnInit, Input } from '@angular/core';
import { Card } from '../card';

@Component({
  selector: 'app-temperature-card',
  templateUrl: './temperature-card.component.html',
  styleUrls: ['./temperature-card.component.sass', '../../equipment-card.component.sass' ]
})
export class TemperatureCardComponent extends Card implements OnInit {


    constructor() { 
        super();
    }

    ngOnInit(): void {
    }

}
