export class Temperature {
    min: number;
    max: number;
    securityLimit: number;
    _currentValue: number;
    previousValue: number;

    constructor(
      min: number,
      max: number,
      securityLimit: number,
      currentValue: number,
      previousValue: number
    ){
      this.min = min;
      this.max = max;
      this.securityLimit = securityLimit;
      this.currentValue = currentValue;
      this.previousValue = previousValue;
    }
    get currentValue(): number {
      const [integers = '0', decimals = '0'] = this._currentValue.toString().split('.');
      return Number.parseFloat(`${integers}.${decimals.substring(0,1)}`);
    }

    set currentValue(value: number){
      this._currentValue = value;
    }
}
