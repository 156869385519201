import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Monitor } from 'src/app/types/monitor';
import { EChartsOption } from 'echarts';
import { BaseSeries } from './series/base.series';
import { SeriesFactory } from './series/series-factory';


const customEndDate = new Date();
customEndDate.setDate(customEndDate.getDate() + 5);
const chart: ChartData = {
  startDate: new Date(),
  endDate: new Date(customEndDate),
  frequency: 1, //minutes
  data: [
    {
      type: "temperature",
      values: [-11, 2, 1 , -20 , -15, -10, -10],
    },
    {
      type: "door",
      values: []

    }
  ],
}

export class ChartData {
  startDate: Date;
  endDate: Date;
  frequency: number; //minutes
  data: BaseSeries[];
}

@Component({
  selector: 'app-monitors-chart',
  templateUrl: './monitors-chart.component.html',
  styleUrls: ['./monitors-chart.component.sass']
})
export class MonitorsChartComponent implements OnInit {

  chartOption: any;

  constructor() { }

  @Input()
  chartData: ChartData;

  ngOnInit(): void {
   this.chartData = chart;

    let legendData = [];
    let yAxisData = [];
    let seriesData = [];

    const xAxisData = this.generateXAxisData();
    this.chartData.data.forEach((baseSeries: BaseSeries) => {
      const series = SeriesFactory.createSeries(baseSeries);
      legendData.push(series.getLegend());
      yAxisData.push(series.getYAxis());
      seriesData.push(series.getSeriesValues(xAxisData.data));
    })

    this.chartOption = {
      toolbox: {
        show: true,
        feature: {
          restore: {},
          saveAsImage: {}
        }
      },
      tooltip: {
        axisPointer: {
          type: 'cross'
        }
      },
      legend: legendData,
      xAxis: xAxisData,
      dataZoom: [{
        type: 'slider',
        show: true,
        xAxisIndex: [0],
        start: 0,
        end: 100
      },
      ],
      yAxis: yAxisData,
      series: seriesData,
    };
  }


  generateXAxisData(): {type: 'category', data: string[]}{
    const xAxisData = [];
    const nextDate = new Date(this.chartData.startDate);

    while(nextDate <= this.chartData.endDate){
      nextDate.setMinutes(nextDate.getMinutes() + this.chartData.frequency);

      const [datePart, timezonePart] = nextDate.toISOString().split("T");
      const [hours, minutes, seconds] = timezonePart.split(".")[0].split(":");

      xAxisData.push(`${datePart} ${hours}:${minutes}`);
    }

    return {
      type: 'category',
      data: xAxisData
    };
  }

}
