import { Local } from './local';
import { Brand } from './brand';

export class UnityType {
    name: string;
    id: number;
    locals: Array<Local>;
    brand?: Brand;

    initializeLocals(locals: Array<Local>){
        return locals.map(local => new Local(
            local.id,
            local.name,
            local.sectors,
            this
        ));
    }

    constructor(
        name: string,
        id: number,
        locals: Array<Local>,
        brand: Brand
    ){
        this.name = name;
        this.id = id;
        this.locals = this.initializeLocals(locals);
        this.brand = brand;
    }
}
