import { Component } from '@angular/core';
import { AuthService } from './services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})
export class AppComponent {
  title = 'sentinel-webapp';

  constructor(
      private authSrv: AuthService
  ){
  }
  drawerOpened: boolean = false;

  public user: any = {
      name: 'roberto'
  }

  isLoggedIn(): boolean{
      return this.authSrv.isLoggedIn();
  }

  showFiller = false;

  drawerOpenedChanged($event: boolean): void {
      this.drawerOpened = $event;
  }
}
