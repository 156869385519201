import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';


//Echarts
import { NgxEchartsModule } from 'ngx-echarts';
import * as echarts from 'echarts';

//Material
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatToolbarModule} from '@angular/material/toolbar';
import { MatMenuModule } from '@angular/material/menu';
import { MatCardModule } from '@angular/material/card';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatTableModule } from '@angular/material/table';

import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MAT_MOMENT_DATE_FORMATS, MomentDateAdapter} from '@angular/material-moment-adapter';



import { AppComponent } from './app.component';
import { LoginComponent } from './views/login/login.component';
import { DashboardComponent } from './views/dashboard/dashboard.component';
import { AreaDropdownComponent } from './components/area-dropdown/area-dropdown.component';
import { MonitorSelectionDropdownComponent } from './components/monitor-selection-dropdown/monitor-selection-dropdown.component';

import { AppRoutingModule } from './app-routing.module';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ViewContainerRefDirective } from './directives/view-container-ref.directive';
import { EquipmentContainerComponent } from './components/equipment-container/equipment-container.component';
import { VisualComponent } from './views/visual/visual.component';
import { AlarmComponent } from './views/alarm/alarm.component';
import { ReportComponent } from './views/report/report.component';
import { UserComponent } from './views/user/user.component';
import { HomeComponent } from './views/home/home.component';
import { EquipmentCardComponent } from './components/equipment-card/equipment-card.component';
import { CurrentCardComponent } from './components/equipment-card/cards/current-card/current-card.component';
import { TemperatureCardComponent } from './components/equipment-card/cards/temperature-card/temperature-card.component';
import { TemperatureDoorCardComponent } from './components/equipment-card/cards/temperature-door-card/temperature-door-card.component';
import { TemperatureHumidityCardComponent } from './components/equipment-card/cards/temperature-humidity-card/temperature-humidity-card.component';
import { OfflineCardComponent } from './components/equipment-card/cards/offline-card/offline-card.component';
import { LoadingSpinnerComponent } from './components/loading-spinner/loading-spinner.component';
import { URLPathNamePipe } from './pipes/urlpath-name.pipe';
import { EquipmentDetailsComponent } from './views/equipment-details/equipment-details.component';
import { EventTableComponent } from './components/event-table/event-table.component';

//SERVICES
import { AuthInterceptorService } from './services/auth-interceptor.service';
import { LevelStatusIndicatorComponent } from './components/level-status-indicator/level-status-indicator.component';
import { EquipmentStatisticComponent } from './components/equipment-statistic/equipment-statistic.component';
import { DoorStatisticComponent } from './components/equipment-statistic/statistics/door-statistic/door-statistic.component';
import { TemperatureStatisticComponent } from './components/equipment-statistic/statistics/temperature-statistic/temperature-statistic.component';
import { PeriodDatesValidatorDirective } from './directives/period-dates-validator.directive';
import { AcceptedDialogComponent } from './components/accepted-dialog/accepted-dialog.component';

//PROVIDERS
import { RouteReuseStrategy } from "@angular/router";
import { CustomRouteReuseStrategy } from "./providers/custom-route-reuse-strategy.provider";
import { MonitorsChartComponent } from './components/monitors-chart/monitors-chart.component';
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    AreaDropdownComponent,
    MonitorSelectionDropdownComponent,
    ViewContainerRefDirective,
    EquipmentContainerComponent,
    VisualComponent,
    AlarmComponent,
    ReportComponent,
    UserComponent,
    HomeComponent,
    EquipmentCardComponent,
    CurrentCardComponent,
    TemperatureCardComponent,
    TemperatureDoorCardComponent,
    TemperatureHumidityCardComponent,
    OfflineCardComponent,
    LoadingSpinnerComponent,
    URLPathNamePipe,
    EquipmentDetailsComponent,
    EventTableComponent,
    LevelStatusIndicatorComponent,
    EquipmentStatisticComponent,
    DoorStatisticComponent,
    TemperatureStatisticComponent,
    PeriodDatesValidatorDirective,
    AcceptedDialogComponent,
    MonitorsChartComponent,
  ],
  imports: [
      FormsModule,
      BrowserModule,
      MatCardModule,
      MatFormFieldModule,
      MatInputModule,
      MatSelectModule,
      MatButtonModule,
      MatSidenavModule,
      MatIconModule,
      MatListModule,
      MatToolbarModule,
      MatMenuModule,
      ReactiveFormsModule,
      AppRoutingModule,
      BrowserAnimationsModule,
      HttpClientModule,
      MatProgressSpinnerModule,
      MatSnackBarModule,
      MatProgressBarModule,
      MatGridListModule,
      MatDatepickerModule,
      MatTableModule,
      NgxEchartsModule.forRoot({
          echarts,
      }),
  ],
  providers: [
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_LOCALE, useValue: 'pt-BR'},
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptorService, multi: true},
    {provide: RouteReuseStrategy, useClass: CustomRouteReuseStrategy}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
