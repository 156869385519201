import { Current } from './../../types/current';
import { Router } from '@angular/router';
import { Humidity } from './../../types/humidity';
import { Door } from './../../types/door';
import { Temperature } from './../../types/temperature';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Monitor } from '../../types/monitor';
import { Alarm, WARNING_TYPE } from '../../types/alarm';
import { differenceDate } from '../../utils/date-utils';
import { WebSocketService } from '../../services/web-socket.service';
import { AuthService } from '../../services/auth.service';

interface AlarmFilterParams {
    active?: boolean;
}

@Component({
    selector: 'app-equipment-card',
    templateUrl: './equipment-card.component.html',
    styleUrls: ['./equipment-card.component.sass']
})
export class EquipmentCardComponent implements OnInit, OnDestroy {

    @Input()
    removeBoxShadow: boolean;

    @Input()
    monitor: Monitor;
    public user: string;

    constructor(
      private authSrv: AuthService,
      private webSocketService: WebSocketService,
      private router: Router
    ) { }

    keyOfType(alarm: Alarm): string {
        if(!alarm) return "";
        const levelString: string = WARNING_TYPE[alarm.type] || WARNING_TYPE[0];
        return levelString
            .replace('_', '-')
            .toLowerCase();
    }

    getPriorAlarm(): Alarm {
        if(this.monitor.alarms.length) {
          return this.monitor.alarms.reduce((acc: Alarm, alarm: Alarm) => alarm.type > acc.type ? alarm : acc);
        } else {
          return null;
        }
    }

    get maxLastUpdateTime(): Date {
        return this.monitor.lastUpdateTime;
    }

    getSecondsString(): string{
        const seconds = differenceDate(new Date(), this.maxLastUpdateTime)/1000;

        const plural = (quantity: number, singular: string, plural: string) => `${quantity} ${quantity > 1 ? plural : singular}`;

        let formattedString = "";
        if(seconds < 60) formattedString = '0 minutos'
        else if(seconds < 60*60) formattedString = plural(Math.round(seconds/60), 'minuto', 'minutos');
        else if(seconds < 60*60*24) formattedString = plural(Math.round(seconds/60/60), 'hora', 'horas');
        else formattedString = `${Math.round(seconds/60/60/24)} dias`;

        return `${formattedString}`;
    }

    ngOnInit(): void {
        this.user = this.authSrv.getUser();
        let alarmTopic = `/topic/${this.user}/${this.monitor.monitorable.code}/${this.monitor.code}/alarmcount`
        this.webSocketService.connectTopic(alarmTopic, alarmTopic, msgValue => {
          let alarms: Array<Alarm> = JSON.parse(msgValue.body);
          //console.log('NUMERO DE ALARMES PENDENTES PARA O MONITOR: ', this.monitor.code, alarms.length);
          this.monitor.alarms = alarms.map(alarm => this.monitor.initAlarm(alarm));
        })
        if (!!this.monitor.type && this.monitor.type.indexOf('TEMPERATURE') >= 0) {
            this.webSocketService.connectMonitorVariableValue(this.monitor.code, 'temperature', msgValue => {
                if (!!this.monitor.temperatures &&
                    this.monitor.temperatures.length > 0) {
                    let first: Temperature = this.monitor.temperatures[0];
                    let oldValue = first.currentValue;
                    first.currentValue = parseFloat(msgValue.body)
                    first.previousValue = oldValue
                    this.monitor.lastUpdateTime = new Date()
                    this.monitor.status = 'ACTIVE'
                }
            });
        }
        if (!!this.monitor.type && this.monitor.type.indexOf('DOOR') >= 0) {
            this.webSocketService.connectMonitorVariableValue(this.monitor.code, 'door', msgValue => {
                if (!!this.monitor.doors &&
                    this.monitor.doors.length > 0) {
                    let first: Door = this.monitor.doors[0];
                    first.currentValue = msgValue.body
                    this.monitor.lastUpdateTime = new Date()
                    this.monitor.status = 'ACTIVE'
                }
            });
        }
        if (!!this.monitor.type && this.monitor.type.indexOf('HUMIDITY') >= 0) {
            this.webSocketService.connectMonitorVariableValue(this.monitor.code, 'humidity', msgValue => {
                if (!!this.monitor.humidities &&
                    this.monitor.humidities.length > 0) {
                    let first: Humidity = this.monitor.humidities[0];
                    let oldValue = first.currentValue;
                    first.currentValue = parseFloat(msgValue.body)
                    first.previousValue = oldValue
                    this.monitor.lastUpdateTime = new Date()
                    this.monitor.status = 'ACTIVE'
                }
            });
        }
        if (!!this.monitor.type && this.monitor.type.indexOf('CURRENT') >= 0) {
            this.webSocketService.connectMonitorVariableValue(this.monitor.code, 'current', msgValue => {
                if (!!this.monitor.currents &&
                    this.monitor.currents.length > 0) {
                    let first: Current = this.monitor.currents[0];
                    let oldValue = first.currentValue;
                    first.currentValue = parseFloat(msgValue.body)
                    first.previousValue = oldValue
                    this.monitor.lastUpdateTime = new Date()
                    this.monitor.status = 'ACTIVE'
                }
            });
        }
    }

    ngOnDestroy(): void {
        let messageTypes: Array<string> = [];
        if (!!this.monitor.type && this.monitor.type.indexOf('TEMPERATURE') >= 0)
            messageTypes.push('temperature');
        if (!!this.monitor.type && this.monitor.type.indexOf('DOOR') >= 0)
            messageTypes.push('door');
        if (!!this.monitor.type && this.monitor.type.indexOf('HUMIDITY') >= 0)
            messageTypes.push('humidity');
        if (!!this.monitor.type && this.monitor.type.indexOf('CURRENT') >= 0)
            messageTypes.push('current');
        messageTypes.forEach(messageType => {
            this.webSocketService.disconectMonitorVariableValue(this.monitor.code, messageType);
        })
        let alarmTopic = `/topic/${this.user}/${this.monitor.code}/alarmcount`
        this.webSocketService.disconnectTopic(alarmTopic)
    }

    goToCardDetails(monitor: Monitor){
      this.router.navigateByUrl('/details/' + monitor.code, { state: {'teste': 1, 'monitor': monitor} });
    }
}
