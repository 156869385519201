import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { NotificationSnackService } from '../../services/notification-snack.service';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.sass'],
  providers: [
      AuthService
  ]
})

export class LoginComponent implements OnInit {

    public form: FormGroup;
    public loading: boolean;

    //Controle para exibir as validações ao usuário
    private formSubmitAttempt: boolean;

    constructor(
        private formBuilder: FormBuilder,
        private authService: AuthService,
        private notificationSrv: NotificationSnackService,
        private router: Router
    ) { }

    ngOnInit(): void {
        this.form = this.formBuilder.group({
            userName: ['', Validators.required],
            password: [''],
        });
    }

    isFieldInvalid(field: string): boolean {
        return(
            (!this.form.get(field).valid && this.form.get(field).touched) ||
                (this.form.get(field).untouched && this.formSubmitAttempt)
        );
    }

    onSubmit(): void{
        if (this.form.valid){
            this.loading = true
            const subscription = this.authService.login(this.form.value).subscribe(
                success => {
                    this.loading = false
                    this.router.navigate(['/'])
                },
                err => {
                    this.loading = false;
                    this.notificationSrv.notify(err.error || "Um error desconhecido ocorreu no sistema");
                }
            );

        }
        this.formSubmitAttempt = true;
    }

}
