import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(
      private authSrv: AuthService,
      private router: Router
  ) { }

  canActivate(
      next: ActivatedRouteSnapshot,
      state: RouterStateSnapshot,
  ): boolean {
      const loggedIn = this.authSrv.isLoggedIn();
      if(loggedIn){
          return true;
          this.router.navigate(['/']);
      }
      else {
          console.log('não ta logado');
        this.router.navigate(['/login']);
        return false;
      }
  }
}
