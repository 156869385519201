<app-loading-spinner [loading]="loadingMonitor"></app-loading-spinner>

<ng-template #titleBar>
  <app-monitor-selection-dropdown style="flex:1" [brands]="brands" [monitor]="monitor" (filterChanged)="toggleCardVisibility($event, false)"></app-monitor-selection-dropdown>
</ng-template>

<div class="cards-container" *ngIf="!loadingMonitor && cardVisible">
  <mat-card class="equipment" >
    <app-equipment-card *ngIf="!loadingMonitor" [monitor]="monitor" [removeBoxShadow]="true"></app-equipment-card>

    <div class="right-panel">
        <div class="date" *ngIf="details">
            <form [formGroup]="statisticsForm">
                <mat-form-field class="date-form-field">
                    <input (dateChange)="fetchDetails()" [matDatepickerFilter]="startDateFilter" matInput [matDatepicker]="startDatePicker" formControlName="startDate" placeholder="" disabled>
                    <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
                    <mat-datepicker #startDatePicker [disabled]="loadingDetails"></mat-datepicker>
                </mat-form-field>
                <span style="margin-left:1rem;margin-right:1rem">a</span>

                <mat-form-field class="date-form-field">
                    <input (dateChange)="fetchDetails()" matInput [matDatepickerFilter]="endDateFilter" [matDatepicker]="endDatePicker" formControlName="endDate" placeholder="" disabled>
                    <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
                    <mat-datepicker #endDatePicker [disabled]="loadingDetails"></mat-datepicker>
                </mat-form-field>
                <div *ngIf="statisticsForm.errors?.periodDates" class="danger error">
                    Período inválido, insira uma data de início menor do que o período final.
                </div>
            </form>
        </div>
        <div class="monitor-image">
            <img *ngIf="details" [src]="details.miniMapImage || './assets/img/noimage.png'"/>
            <mat-spinner cl *ngIf="!details && loadingDetails"></mat-spinner>
        </div>
    </div>

  </mat-card>


  <mat-card class="statistics" >
    <!--TODO insert loading-->
    <h2>Estatísticas</h2>
    <div *ngIf="loadingDetails">
        <mat-spinner></mat-spinner>
    </div>
    <div *ngIf="details && !loadingDetails && !detailsError.length" class="statistics-container">
      <app-equipment-statistic *ngFor="let statistic of details.statistics" [statistic]="statistic"></app-equipment-statistic>
    </div>
    <div *ngIf="!loadingDetails && detailsError.length" class="error">
        <mat-error>{{detailsError}}</mat-error>
    </div>
  </mat-card>

  <mat-card class="chart">
    <h2>Gráfico</h2>
    <app-monitors-chart>
    </app-monitors-chart>
  </mat-card>

  <mat-card class="events">
    <h2>Eventos</h2>
    <app-event-table *ngIf="monitor" [alarms]="monitor.alarms" [monitor]="monitor"></app-event-table>
  </mat-card>
</div>

<div *ngIf="!cardVisible">
  Selecione um monitor
</div>


