<app-loading-spinner [loading]="loading"></app-loading-spinner>
<div class="header">
  <mat-icon class="close-button" (click)="closeDialog()">close</mat-icon>
</div>

<div class="general-info-container" *ngIf="!loading">
  <h4 class="alarm-date">{{getFormattedDate()}}</h4>
  <h3 class="monitor-identification">{{monitor.code}}.{{monitor.name}}</h3>
  <p class="alarm-message">{{alarm.message}}</p>
  <div class="status-container">
    <p class="status-level">{{getWarningLevelLabel(alarm.type)}}</p>
    <p class="status-message">{{alarm.status ? alarm.endDate + " voltou ao normal" : "Inativo"}}</p>
  </div>
</div>

<div class="dialog-body" *ngIf="!loading">
  <form [formGroup]="acceptForm" (ngSubmit)="sendEventReason()">
    <mat-form-field appearance="outline" class="d-block">
      <mat-label>Responsável</mat-label>
      <input matInput formControlName="manager" type="text"/>
    </mat-form-field>

    <mat-form-field appearance="outline" class="d-block">
      <mat-label>Justificativa</mat-label>
      <mat-select formControlName="reason">
        <mat-option *ngFor="let reason of reasonOptions" [value]="reason.id">{{reason.label}}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline" class="d-block">
      <mat-label>Observação</mat-label>
      <textarea formControlName="description" matInput>
      </textarea>
    </mat-form-field>

    <button mat-button class="justify-button" color="primary">JUSTIFICAR</button>
  </form>
</div>

