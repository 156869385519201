<div class="sectors-wrapper">
  <div class="sector-container" *ngFor="let sector of sectors; trackBy:trackByFnSectors">
    <div class="sector-name">
      {{sector.local.unity.brand.name}}.{{sector.local.unity.name}}.{{sector.local.name}}
    </div>
    <div class="sector-name">
      {{sector.name}}
    </div>
    <div class="sector-monitors">
      <app-equipment-card
        *ngFor="let monitor of getMonitors(sector); trackBy: trackByFnMonitors"
        class="equipment-card"
        [monitor]="monitor">
      </app-equipment-card>
    </div>
  </div>
</div>
