export abstract class BaseSeries {
  public type: "door" | "temperature";
  public values: any[];

  constructor(series: BaseSeries){
    this.type = series.type;
    this.values = series.values;
  }

  abstract getYAxis?(): any;
  abstract getLegend?(): any;

  generateValues?(xAxisValues: string[]){
    const ret = [];

    this.values.forEach((value, idx) => {
      if(value){
        ret.push([xAxisValues[idx], value]);
      }
    })

    return ret;
  }

}
