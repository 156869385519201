<p class="description">Temperatura</p>
<ul class="items-list">
  <li>
    <span class="item">Mínima</span><span class="value"> {{statistic.min}} @ {{statistic.minAtTime}}</span>
  </li>
  <li>
    <span class="item">Máxima</span><span class="value"> {{statistic.max}} @ {{statistic.maxAtTime}}</span>
  </li>
  <li>
    <span class="item">Média:</span><span class="value"> {{statistic.avg}}</span>
  </li>
</ul>
