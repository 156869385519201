import { Monitorable } from './monitorable';
import { Monitor } from './monitor';
import { Local } from './local';

export class Sector{
    id: number;
    name: string;
    monitorables: Array<Monitorable>;
    local: Local

    initializeMonitorables(monitorables: Array<Monitorable>){
        return monitorables.map(monitorable => new Monitorable(
            monitorable.name,
            monitorable.code,
            monitorable.description,
            monitorable.monitors,
            this
        ))

    }

    constructor(
        id: number,
        name: string,
        monitorables: Array<Monitorable>,
        local: Local
    ){
        this.id = id;
        this.name = name;
        this.monitorables = this.initializeMonitorables(monitorables);
        this.local = local;
    }

}

