import { Monitor } from './monitor';
import { Sector } from './sector';

export class Monitorable {
    name: string;
    code: string;
    description :string;
    monitors: Array<Monitor>;
    sector: Sector;

    initializeMonitors(monitors: Array<Monitor>){
        return monitors.map(monitor => new Monitor(
            monitor.name,
            monitor.code,
            monitor.description,
            monitor.status,
            monitor.lastUpdateTime,
            monitor.type,
            monitor.alarms,
            monitor.temperatures,
            monitor.currents,
            monitor.humidities,
            monitor.doors,
            this
        ))

    }

    constructor(
        name: string,
        code: string,
        description :string,
        monitors: Array<Monitor>,
        sector: Sector,
    ){
        this.name = name;
        this.code = code;
        this.description = description;
        this.monitors = this.initializeMonitors(monitors);
        this.sector = sector;
    }
}
