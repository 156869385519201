import { Injectable } from '@angular/core';
import { Brand } from '../types/brand';
import { Local } from '../types/local';
import { UnityType } from '../types/unity-type';
import { Sector } from '../types/sector';


export interface dropdownSelection {
  brand: Brand;
  unity: UnityType;
  local: Local;
  sector: Sector;
}

@Injectable({
  providedIn: 'root'
})
export class CacheService {

  cachedDropdownSelection: dropdownSelection;

  constructor() { }

  saveDropdownSelection(filterSaved: dropdownSelection){
    this.cachedDropdownSelection = filterSaved;
  }

  loadDropdownSelection(): dropdownSelection {
    return this.cachedDropdownSelection;
  }
}
