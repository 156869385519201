<app-loading-spinner [loading]="loading"></app-loading-spinner>
<form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div>
        <img src="./assets/img/logo.png"/>
    </div>
    <div>
        <mat-form-field appearance="outline">
            <mat-label>Login</mat-label>
            <input matInput formControlName="userName" placeholder="Usuário" required>
            <mat-error *ngIf="isFieldInvalid('userName')">
                <p>Por favor, informe seu nome de usuário!</p>
            </mat-error>
        </mat-form-field>
    </div>
    <div>
        <mat-form-field appearance="outline">
            <mat-label>Senha</mat-label>
            <input matInput type="password" formControlName="password" placeholder="Senha" required>
            <mat-error *ngIf="isFieldInvalid('userName')">
                <p>Por favor, informe sua senha!</p>
            </mat-error>
        </mat-form-field>
    </div>
    <div>
        <button mat-button color="primary" id="enter-button" [disabled]="loading">Entrar</button>
    </div>
</form>
