import { Component, OnInit } from '@angular/core';
import { Card } from '../card';

@Component({
  selector: 'app-current-card',
  templateUrl: './current-card.component.html',
  styleUrls: [
      './current-card.component.sass',
      '../../equipment-card.component.sass'
  ]
})
export class CurrentCardComponent extends Card implements OnInit {

  constructor() {
      super();
  }


  ngOnInit(): void {
  }

}
