<div class="card-container {{keyOfType(getPriorAlarm())}} {{monitor.type}} " >
    <!--mat-card [class.border-red]="monitor.temp > monitor.maxTemp"-->
    <mat-card style="display:flex;flex-direction:column; justify-content:space-between" [class.bg-grey]="monitor.status == 'INACTIVE'" [class.remove-box-shadow]="removeBoxShadow">
        <div class="header">
            <div class="header-info">
                 <mat-card-subtitle (click)="goToCardDetails(monitor)">{{monitor.monitorable.code}} - {{monitor.code}}</mat-card-subtitle>
                 <mat-card-title class="monitor-name" [title]="monitor.name" style="font-size:24px">{{monitor.name}}</mat-card-title>
                 <mat-card-subtitle>{{monitor.description}}</mat-card-subtitle>
            </div>
            <div class="alarm">
              <div class="badge {{keyOfType(getPriorAlarm())}}" *ngIf="monitor.alarms.length > 0 "
                style="border-radius: 50%; color: white; text-align:center; height:15px; width:15px;display:flex; justify-content: center; justify-items:center; align-content:center; align-items:center;font-weight:bold">
                    {{monitor.alarms.length}}
                </div>
            </div>
        </div>

        <mat-card-content *ngIf="monitor.status == 'ACTIVE'; else statusInactive" [ngSwitch]="monitor.type">
            <app-temperature-card *ngSwitchCase="'TEMPERATURE'" [monitor]="monitor"></app-temperature-card>
            <app-temperature-door-card *ngSwitchCase="'TEMPERATURE_DOOR'" [monitor]="monitor"></app-temperature-door-card>
            <app-temperature-humidity-card *ngSwitchCase="'TEMPERATURE_HUMIDITY'" [monitor]="monitor"></app-temperature-humidity-card>
            <app-current-card *ngSwitchCase="'CURRENT'" [monitor]="monitor"></app-current-card>
        </mat-card-content>
        <ng-template #statusInactive>
            <mat-icon style="text-align:center; width:100%;font-size: 58px;margin-bottom:1rem">wifi_off</mat-icon>
            <p style="text-align:center; font-size:16px">Sem conexão</p>
        </ng-template>
        <div class="last-update-time" style="display:flex; align-content:center;">
            <p style=" margin-bottom:0; font-size: 12px"><mat-icon style="font-size:1rem">refresh</mat-icon>Atualizado {{getSecondsString()}} atrás</p>
        </div>
    </mat-card>
</div>
