import { Component, OnInit, OnDestroy, ViewChild, TemplateRef } from '@angular/core';
import { Sector } from '../../types/sector';
import { Router, ActivatedRoute, NavigationEnd, RouterEvent } from '@angular/router';
import { WithTitleBar } from '../../types/with-title-bar';
import { Brand } from '../../types/brand';
import { Monitor } from '../../types/monitor';
import { BrandService } from '../../services/brand.service';
import { MonitorService } from '../../services/monitor.service';
import { Subject } from 'rxjs';
import { takeUntil, filter } from 'rxjs/operators';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {MAT_DATE_FORMATS} from '@angular/material/core';
import { periodDatesValidator }  from '../../directives/period-dates-validator.directive';

import * as _moment from 'moment';

const moment = _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'ddd, DD MMM',
    monthYearLabel: 'LL',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'LL',
  },
};

@Component({
  selector: 'app-equipment-details',
  templateUrl: './equipment-details.component.html',
  styleUrls: ['./equipment-details.component.sass'],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
  ]

})

export class EquipmentDetailsComponent implements OnInit, OnDestroy, WithTitleBar {

  @ViewChild('titleBar', { static: true}) titleBar: TemplateRef<any>;

  public monitor: Monitor;
  public monitorCode: string;
  public brands: Array<Brand>;
  public loadingMonitor: boolean = false;
  public loadingDetails: boolean = false;


  public detailsError: string = "";

  public cardVisible: boolean = true;

  public details: any;

  public maxDate: moment.Moment = moment().add(45, 'days');

  public statisticsForm = new FormGroup({
    startDate: new FormControl(moment(), [Validators.required]),
    endDate: new FormControl(moment(), [Validators.required])
  }, {validators: periodDatesValidator});


  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private brandSrv: BrandService,
    private monitorSrv: MonitorService
  ) {
    const currentNavigation = this.router.getCurrentNavigation();
    this.startDateFilter = this.startDateFilter.bind(this);
    this.endDateFilter = this.endDateFilter.bind(this);

    if(currentNavigation && currentNavigation.extras.state){
      this.monitor = currentNavigation.extras.state.monitor;
    } else {
      this.activatedRoute.params.subscribe(params => {
        this.monitorCode = params.id;
      })
    }
  }


  startDateFilter(date: moment.Moment | null): boolean {
    return true;
  }

  endDateFilter(date: moment.Moment | null): boolean {
    const minLimit = moment(this.statisticsForm.value.startDate).subtract(45, 'days');
    const maxLimit = moment(this.statisticsForm.value.startDate).add(45, 'days');
    return  date >= minLimit && date <= maxLimit;
  }

  toggleCardVisibility(filterChanged: boolean, visible: boolean = true): void{
    console.log('visibility', visible);
    if(filterChanged){
      this.cardVisible = visible;
    }
    else this.cardVisible = true;
  }

  initializeBrands(brands: Array<Brand>){
    return brands.map(brand => new Brand(
      brand.id,
      brand.name,
      brand.unities
    ))
  }

  ngOnInit(): void {
    this.fetchData();
  }

  ngOnDestroy(): void {
  }

  fetchData(): void {
    if(!this.monitor){
      this.loadingMonitor = true;
    }
    this.brandSrv.getBrands().subscribe(
      brands => {
        this.brands = this.initializeBrands(brands);
        if(!this.monitor){
          this.monitor = this.getMonitorByCode(this.monitorCode);
          this.loadingMonitor = false;
        }

        this.fetchDetails();
      },
      err => {
          this.router.navigate(['/']);
      }
    )
  }

  fetchDetails(){
    if(!this.statisticsForm.errors && this.statisticsForm.valid){
      this.loadingDetails = true;
      this.statisticsForm.disable();
      this.monitorSrv.getDetails(this.monitor.code, this.statisticsForm.value
      ).subscribe(
        details => {
            this.details = details;
            this.detailsError = "";
        },
        err => {
            this.detailsError = "Erro"
            this.loadingDetails = false;
            this.statisticsForm.enable();
            console.log(err)
        },
        () => {
            this.loadingDetails = false
          this.statisticsForm.enable();
        }
      );
    }
  }


  getMonitorByCode(code: string){
    const brands: Brand[] = this.brands;
    for(let i_brand: number = 0; i_brand < brands.length; i_brand++){
      const unities = brands[i_brand].unities; //TODO type
      for(let i_unity: number = 0; i_unity < unities.length; i_unity++){
        const locals = unities[i_unity].locals; //TODO type
        for(let i_local: number = 0; i_local < locals.length; i_local++){
          const sectors = locals[i_local].sectors; //TODO type
          for(let i_sector: number = 0; i_sector < sectors.length; i_sector++){
            const monitorables = sectors[i_sector].monitorables; //TODO type
            for(let i_monitorable: number = 0; i_monitorable < monitorables.length; i_monitorable++){
              const monitors = monitorables[i_monitorable].monitors;
              const foundMonitor = monitors.find(monitor => monitor.code == code);  //TODO type
              if(foundMonitor) return foundMonitor;
            }
          }
        }
      }
    }
    return null;
  }
}
