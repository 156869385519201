import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Monitor } from '../types/monitor';
import { Observable, of} from 'rxjs';
import { delay } from 'rxjs/operators';
import { HttpClient} from '@angular/common/http';
import { environment } from '../../environments/environment';

import * as _moment from 'moment';

const moment = _moment;

@Injectable({
  providedIn: 'root'
})
export class MonitorService {

  constructor(
    private httpClient: HttpClient,
    private router: Router
  ) {
  }

  getDetails(code: string, filter?: {startDate: moment.Moment, endDate: moment.Moment}): Observable<any> { //TODO change type
    const dateFormat = "YYYY-MM-DD"
    return this.httpClient.get(environment.apiUrl + "/details/" + code, {params: {
      startDate: filter.startDate.format(dateFormat),
      endDate: filter.endDate.format(dateFormat),
    }} );
  }

  goToCardDetails(monitor: Monitor){
    this.router.navigateByUrl('/details/' + monitor.code, { state: {'monitor': monitor} });
  }
}
