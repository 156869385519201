import { Component, OnInit, ViewChild, ComponentFactoryResolver, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { Router, NavigationEnd } from '@angular/router';
import { Observable } from 'rxjs';
import { URLPathNamePipe } from '../../pipes/urlpath-name.pipe';
import { WithTitleBar } from '../../types/with-title-bar';
import { WebSocketService } from '../../services/web-socket.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.sass']
})
export class HomeComponent implements OnInit {


    public user: string;
    public activatedRoute: NavigationEnd;
    public titleBar: TemplateRef<Component>;
    public componentOutlet: any;
    public alarmCount: number = 0;

    constructor(
        private authSrv: AuthService,
        private router: Router,
        private root: ViewContainerRef,
        private webSocketService: WebSocketService
    ){
      router.events.subscribe(event => {
          if(event instanceof NavigationEnd){
              this.activatedRoute = event;
          }
      });
    }

    drawerOpened: boolean = true;

    showFiller = false;

    drawerOpenedChanged($event: boolean): void {
        this.drawerOpened = $event;
    }

    isLoggedIn(): boolean {
        return this.authSrv.isLoggedIn();
    }

    ngOnInit(): void {
        console.log('init');
      this.user = this.authSrv.getUser();
      let topic = `/topic/${this.user}/alarmcount`
      this.webSocketService.connectTopic(topic, topic, msgValue => {
        let messageCount = parseFloat(msgValue.body);
        console.log('NUMERO DE ALARMES PENDENTES: ', messageCount);
        this.alarmCount = messageCount;
      })
    }

    ngOnDestroy(): void {
      let topic = `/topic/${this.user}/alarmcount`
      this.webSocketService.disconnectTopic(topic)
    }

    logout(): void {
      this.authSrv.logout();
    }

    triggerActivated(event: WithTitleBar){
      this.loadTitleBar(event);
    }

    loadTitleBar(loadedComponent: WithTitleBar){
      this.titleBar = loadedComponent.titleBar;
      console.log(this.titleBar);
    }
}
