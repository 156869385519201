import { Directive } from '@angular/core';
import { NG_VALIDATORS, ValidationErrors, ValidatorFn, FormGroup, Validator, AbstractControl} from '@angular/forms';

import * as _moment from 'moment';

const moment = _moment;

export const periodDatesValidator: ValidatorFn = (control: FormGroup) => {
  const startDate = control.get('startDate');
  const endDate = control.get('endDate');

  const minLimit = moment(startDate.value).subtract(45, 'days');
  const maxLimit = moment(startDate.value).add(45, 'days');

    return !(startDate.value <= endDate.value && endDate.value >= startDate.value) ||
            !(endDate.value >= minLimit && endDate.value <= maxLimit)
        ? {periodDates: true} : null;
};

@Directive({
  selector: '[appPeriodDatesValidator]',
  providers: [{provide: NG_VALIDATORS, useExisting: PeriodDatesValidatorDirective,
  multi: true}]
})
export class PeriodDatesValidatorDirective implements Validator{
  validate(control: AbstractControl): ValidationErrors {
    return periodDatesValidator(control);
  }

}
