import { map } from 'rxjs/operators';
import { Brand } from './../../types/brand';
import { Component, OnInit,  Input, OnChanges } from '@angular/core';
import { Router } from '@angular/router';
import { Sector } from '../../types/sector';
import { Monitor } from '../../types/monitor';
import { Local } from '../../types/local';
import { UnityType } from '../../types/unity-type';



//Mudar para SectorsContainer
@Component({
    selector: 'app-equipment-container',
    templateUrl: './equipment-container.component.html',
    styleUrls: ['./equipment-container.component.sass']
})
export class EquipmentContainerComponent implements OnInit, OnChanges {


    constructor(
      private router: Router
    ) {
    }

    @Input()
    sectors: Array<Sector>;


    trackByFnMonitors(index, item){
      return item.code;
    }

    trackByFnSectors(index, sector){
      return sector.id;
    }
    getMonitors(sector: Sector){
        return sector.monitorables.reduce((allMonitors, monitorable) => {
            monitorable.monitors.forEach(monitor => allMonitors.push(monitor));

            return allMonitors;

        }, []);
    }

    ngOnInit(): void { 
    }

    ngOnChanges(): void{
      console.log('sectors', this.sectors);
    }

}
