import { BaseSeries } from './base.series';

export class TemperatureSeries extends BaseSeries {

  getLegend() {
    return {name: 'Temperatura'};
  }

  getYAxis() {
    return {
      type: 'value',
      axisLabel: {
        formatter: '{value} ºC'
      }
    };
  }

  getSeriesValues(xAxisValues: string[]) {
    return {
      name: 'Temperatura',
      tooltip: {
        formatter: (params) => {
          const {marker, seriesName, data} = params;
          return `
              ${seriesName}
              <br/>
              ${marker}${data[0]} <span style="font-weight: 600">${data[1]}ºC</span>
            `
        }
      },
      //data: [-11, 2, 1 , -20 , -15, -10, -10],
      data: this.generateValues(xAxisValues),
      type: 'line',
      animationDelay: function(idx) {
        return idx * 250;
      },
    }
  }
}
