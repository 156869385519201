<mat-table [dataSource]="alarms">
  <ng-container matColumnDef="level">
    <mat-header-cell *matHeaderCellDef> Nível </mat-header-cell>
      <mat-cell *matCellDef="let element"> <app-level-status-indicator [type]="element.type"></app-level-status-indicator> </mat-cell>
  </ng-container>

  <ng-container matColumnDef="hour">
    <mat-header-cell *matHeaderCellDef> Hora </mat-header-cell>
    <mat-cell *matCellDef="let element">
      {{element.startDate.getDate()}}/{{element.startDate.getMonth()+1}}/{{element.startDate.getFullYear()}}
      {{padNumber(element.startDate.getHours())}}:{{padNumber(element.startDate.getMinutes())}}
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="message">
    <mat-header-cell *matHeaderCellDef> Mensagem </mat-header-cell>
    <mat-cell *matCellDef="let element"> {{element.message}} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="status">
    <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
    <mat-cell *matCellDef="let element"> {{element.status ? padNumber(element.endDate.getHours()) + ":" + padNumber(element.endDate.getMinutes()) + " retornou ao normal" : "Inativo"}} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="accepted">
    <mat-header-cell *matHeaderCellDef> Reconhecido </mat-header-cell>
    <mat-cell *matCellDef="let element"> <button (click)="openAcceptedDialog(element, monitor)" mat-button>Reconhecer</button> </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
</mat-table>
