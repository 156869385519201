import { Monitorable } from './monitorable';
import { Alarm } from './alarm';
import { Temperature } from './temperature';
import { Current } from './current';
import { Door } from './door';
import { Humidity } from './humidity';



export class Monitor {

    name: string;
    code: string;
    description: string;
    status: string;
    lastUpdateTime: Date;
    type: string;
    alarms: Array<Alarm>;
    temperatures: Array<Temperature>;
    currents: Array<Current>;
    humidities: Array<Humidity>;
    doors: Array<Door>;
    monitorable: Monitorable;

    initAlarm(alarm: Alarm){
        return new Alarm(
            alarm.id,
            alarm.type,
            alarm.startDate,
            alarm.endDate,
            alarm.message,
            alarm.status,
            this
        );
    }

    initTemperature(temperature: Temperature){
      return new Temperature(
        temperature.min,
        temperature.max,
        temperature.securityLimit,
        temperature.currentValue,
        temperature.previousValue
      );
    }

    initCurrent(current: Current){
      return new Current(
        current.min,
        current.max,
        current.securityLimit,
        current.currentValue,
        current.previousValue
      );
    }
    constructor(
        name: string,
        code: string,
        description: string,
        status: string,
        lastUpdateTime: any,
        type: string,
        alarms: Array<Alarm>,
        temperatures: Array<Temperature>,
        currents: Array<Current> = [],
        humidities: Array<Humidity> = [],
        doors: Array<Door>,
        monitorable: Monitorable
    ){
        this.name = name;
        this.code = code;
        this.description = description;
        this.status = status;
        this.lastUpdateTime = new Date(lastUpdateTime);
        this.type = type;
        this.alarms = alarms.map(alarm => this.initAlarm(alarm));
        this.temperatures = temperatures.map(temperature => this.initTemperature(temperature));;
        this.currents = currents.map(current => this.initCurrent(current));;
        this.humidities = humidities;
        this.doors = doors;
        this.monitorable = monitorable;
    }

    deleteAlarm(alarmId: number){
        this.alarms = this.alarms.filter(alarm => alarm.id != alarmId);
    }
}
