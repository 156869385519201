import { Component, OnInit } from '@angular/core';
import { Statistic } from '../../statistic';
import { EquipmentStatisticComponent } from '../../equipment-statistic.component';


export interface TemperatureStatistic extends Statistic {
  min: number;
  max: number;
  avg: number;
  maxAtTime: string;
  minAtTime: string;
}

@Component({
  selector: 'app-temperature-statistic',
  templateUrl: './temperature-statistic.component.html',
  styleUrls: [
    './temperature-statistic.component.sass',
    '../../equipment-statistic.component.sass'
  ]
})
export class TemperatureStatisticComponent extends EquipmentStatisticComponent implements OnInit {

  statistic: TemperatureStatistic;

  ngOnInit(): void {
  }

}
