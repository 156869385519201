import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { WithTitleBar } from '../../types/with-title-bar';

@Component({
  selector: 'app-visual',
  templateUrl: './visual.component.html',
  styleUrls: ['./visual.component.sass']
})
export class VisualComponent implements OnInit, WithTitleBar {

  @ViewChild('titleBar', { static: true}) titleBar: TemplateRef<any>;


  constructor() { }

  ngOnInit(): void {
  }

}
