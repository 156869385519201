<div class="temp" style="display:flex; align-items: center; justify-content: center; align-items: center;height: 4rem">
    <div class="temp-header" style="display:flex; flex-direction:column; align-items: flex-end; justify-items:center">
        <div *ngIf="monitor.temperatures[0].max">Max {{monitor.temperatures[0].max}}º</div>
        <div *ngIf="monitor.temperatures[0].min">Min {{monitor.temperatures[0].min}}º</div>
    </div>
    <div [class]="'temp-info ' + (monitor.temperatures[0].currentValue > monitor.temperatures[0].max ? 'warning' : '')" style="margin-left: 1rem; display:flex; align-items:center">
        <div> {{monitor.temperatures[0].currentValue}} </div>
        <p class="measure">ºC</p>
        <mat-icon >{{monitor.temperatures[0].currentValue > monitor.temperatures[0].previousValue ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}}</mat-icon>
    </div>
</div>
