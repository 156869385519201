import { Routes, RouterModule } from '@angular/router';
import { EquipmentDetailsComponent } from './views/equipment-details/equipment-details.component';
import { LoginComponent } from './views/login/login.component';
import { DashboardComponent } from './views/dashboard/dashboard.component';
import { VisualComponent } from './views/visual/visual.component';
import { AlarmComponent } from './views/alarm/alarm.component';
import { ReportComponent } from './views/report/report.component';
import { UserComponent } from './views/user/user.component';
import { HomeComponent } from './views/home/home.component';
import { AuthGuardService } from './services/auth-guard.service';


const routes : Routes = [
    {
        path: 'login',
        component: LoginComponent
    },
    {
        path: '',
        component: HomeComponent,
        canActivate: [AuthGuardService],
        children: [
            { path: '', redirectTo: '/dashboard', pathMatch: 'full'},
            { path: 'dashboard', component: DashboardComponent },
            { path: 'details/:id', component: EquipmentDetailsComponent },
            { path: 'visual', component: VisualComponent},
            { path: 'alarm', component: AlarmComponent},
            { path: 'report', component: ReportComponent},
            { path: 'user', component: UserComponent}
        ]
    }
];

export default routes;
