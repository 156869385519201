import { Component, OnInit } from '@angular/core';
import { Statistic } from '../../statistic';
import { EquipmentStatisticComponent } from '../../equipment-statistic.component';

export interface DoorStatistic extends Statistic {
  closedPortion: string;
  openedAverage: string;
  openedPortion: string;
  closedAverage: string;
  totalOpenTime: string;
  totalCloseTime: string;
}

@Component({
  selector: 'app-door-statistic',
  templateUrl: './door-statistic.component.html',
  styleUrls: [
    './door-statistic.component.sass',
    '../../equipment-statistic.component.sass'
  ]
})
export class DoorStatisticComponent extends EquipmentStatisticComponent implements OnInit {

  statistic: DoorStatistic;

  ngOnInit(): void {
  }

}
