import { Component, OnInit, Input } from '@angular/core';
import { WARNING_TYPE } from '../../types/alarm';

@Component({
  selector: 'app-level-status-indicator',
  templateUrl: './level-status-indicator.component.html',
  styleUrls: ['./level-status-indicator.component.sass']
})
export class LevelStatusIndicatorComponent implements OnInit {

  constructor() { }

  @Input()
  type: string;

  keyOfType(): string {
    const levelString: string = WARNING_TYPE[this.type] || WARNING_TYPE[0];
    return levelString
      .replace('_', '-')
      .toLowerCase();
  }

  ngOnInit(): void {
    this.type = this.keyOfType();
  }

}
