<div class="temp" style="display:flex; align-items: center; justify-content: center; align-items: center;height: 4rem">
    <div class="temp-header" style="display:flex; flex-direction:column; align-items: flex-end; justify-items:center">
        <div *ngIf="monitor.currents[0].max">Max {{monitor.currents[0].max}}</div>
        <div *ngIf="monitor.currents[0].min">Min {{monitor.currents[0].min}}</div>
    </div>
    <div [class]="'temp-info ' + (monitor.currents[0].currentValue > monitor.currents[0].max ? 'warning' : '')" style="margin-left: 1rem; display:flex; align-items:center">
        <div> {{monitor.currents[0].currentValue}} </div>
        <mat-icon>{{monitor.currents[0].currentValue > monitor.currents[0].previousValue ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}}</mat-icon>
    </div>
</div>
