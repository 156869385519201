<div style="display: flex; flex-direction: column; height:100%">
<mat-toolbar id="top-navbar" *ngIf="isLoggedIn()">
    <mat-toolbar-row style="padding: 0 2rem">
        <div class="toolbar-item">
            <img src="./assets/img/sentinel-logo-top.svg"/>
        </div>
        <div class="toolbar-item alert-activated" *ngIf="alarmCount > 0">
            <mat-icon  class="alert icon" aria-hidden="false" aria-label="alert icon">notifications</mat-icon><span class="alert-label">Alerta importante ({{ alarmCount }} alarmes)</span>
        </div>
        <div class="toolbar-item">
            <img style="height: 50px;" src="./assets/img/dasa_logo.svg"/>
        </div>
    </mat-toolbar-row>
</mat-toolbar>
<mat-sidenav-container style="flex-grow: 1" class="container" *ngIf="isLoggedIn()" autosize>
    <mat-sidenav class="sidenav" mode="side" opened>
        <mat-nav-list id="draw-button-list">
            <button mat-button class="menu-item" (click)="drawerOpened = !drawerOpened">
                <mat-icon>{{drawerOpened ? 'keyboard_arrow_right' : 'keyboard_arrow_left'}}</mat-icon>
            </button>
        </mat-nav-list>
        <mat-nav-list>
            <button [class]="activatedRoute.url.includes('dashboard') ? 'activated-route' : ''" mat-button class="menu-item" [routerLink]="'/dashboard'">
                <mat-icon>web</mat-icon><span [class.drawerOpened]="drawerOpened">Dashboard</span>
            </button>
        </mat-nav-list>
        <mat-nav-list class="hidden">
            <button [class]="activatedRoute.url.includes('visual') ? 'activated-route' : ''" mat-button class="menu-item" [routerLink]="'/visual'">
                <mat-icon>image</mat-icon><span [class.drawerOpened]="drawerOpened">Visual</span>
            </button>
        </mat-nav-list>
        <mat-nav-list class="hidden">
            <button [class]="activatedRoute.url.includes('alarm') ? 'activated-route' : ''" mat-button class="menu-item" [routerLink]="'/alarm'">
                <mat-icon>notifications</mat-icon><span [class.drawerOpened]="drawerOpened">Alarme</span>
            </button>
        </mat-nav-list>
        <mat-nav-list class="hidden">
            <button [class]="activatedRoute.url.includes('report') ? 'activated-route' : ''" mat-button class="menu-item" [routerLink]="'/report'">
                <mat-icon>show_chart</mat-icon><span [class.drawerOpened]="drawerOpened">Relatórios</span>
            </button>
        </mat-nav-list>
        <mat-nav-list class="hidden">
            <button [class]="activatedRoute.url.includes('user') ? 'activated-route' : ''" mat-button class="menu-item" [routerLink]="'/user'">
                <mat-icon>account_box</mat-icon><span [class.drawerOpened]="drawerOpened">Usuários</span>
            </button>
        </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content class="mat-drawer-content">
        <div>
            <mat-toolbar class="main-bar" color="primary">
                <mat-toolbar-row style="padding-left: 2rem">
                    <div class="title-bar">
                    <ng-container [ngIf]="componentOutlet.titleBar" *ngTemplateOutlet="titleBar"></ng-container>
                    </div>
                    <mat-icon class="icon" aria-hidden="false" aria-label="delete icon"></mat-icon>{{user}}
                    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="dashboard-menu">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item (click)="logout()">
                            <mat-icon>exit_to_app</mat-icon>
                            <span>Sair da conta</span>
                        </button>
                    </mat-menu>
                </mat-toolbar-row>
            </mat-toolbar>
            <div class="outlet-container">
                <router-outlet (activate)="triggerActivated($event)"></router-outlet>
            </div>
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>
</div>
