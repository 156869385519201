// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  oAuthClientId : "scada-api",
  oAuthClientSecret : "todobit123",
  oAuthTokenPath : "/oauth/token",
  oAuthUrl : "https://api.sentinel.med.br",
  apiUrl: 'https://api.sentinel.med.br/web-api',
  webSocketEndPoint: 'https://api.sentinel.med.br/monitor-websocket'
  // oAuthUrl : "http://localhost:8889",
  // apiUrl: 'http://localhost:8889/web-api',
  // webSocketEndPoint: 'http://localhost:8889/monitor-websocket'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
