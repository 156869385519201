import { RouteReuseStrategy, ActivatedRouteSnapshot, DetachedRouteHandle } from "@angular/router";

export class CustomRouteReuseStrategy extends RouteReuseStrategy {
    public shouldDetach(route: ActivatedRouteSnapshot): boolean { return false; }
    public store(route: ActivatedRouteSnapshot, detachedTree: DetachedRouteHandle): void {}
    public shouldAttach(route: ActivatedRouteSnapshot): boolean { return false; }
    public retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle { return null; }
    public shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
        //if(future.routeConfig && future.routeConfig.path == "details/:id"){
        if((future.routeConfig && future.routeConfig.path == "details/:id") ||
            (curr.routeConfig && curr.routeConfig.path == "login")){
            console.log('falso')
            return false;
        }
        console.log('verdadeiro');
        return false;
        //console.debug({future, curr});
        //return future.data.reuse || (future.routeConfig === curr.routeConfig);
    }
}
