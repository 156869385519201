<mat-toolbar>
    <mat-toolbar-row class="toolbar-row">
        <div class="menu-container">
            <!--TODO Componentize this -->
            <button class="dashboard-menu" mat-button [matMenuTriggerFor]="brandMenu" aria-label="dashboard-menu">
                <span class="filter-name">{{brandFilter ? brandFilter.name : 'Marca'}}</span><mat-icon style="color: #00bcd4">arrow_drop_down</mat-icon>
            </button>
            <mat-menu #brandMenu="matMenu">
                <button mat-menu-item (click)="selectBrand(null)">Marca</button>
                <button mat-menu-item *ngFor="let brand of brands" (click)="selectBrand(brand)">
                    {{brand.name}}
                </button>
            </mat-menu>


            <button class="dashboard-menu" mat-button [matMenuTriggerFor]="unityMenu" aria-label="dashboard-menu">
                <span class="filter-name">{{ unityFilter ? unityFilter.name : 'Tipo de Unidade'}}</span><mat-icon style="color: #00bcd4">arrow_drop_down</mat-icon>
            </button>
            <mat-menu #unityMenu="matMenu">
                <button mat-menu-item (click)="selectUnity(null)">Tipo de Unidade</button>
                <button mat-menu-item *ngFor="let unity of filteredUnities" (click)="selectUnity(unity)">
                    {{unity.name}}
                </button>
            </mat-menu>


            <button class="dashboard-menu" mat-button [matMenuTriggerFor]="localMenu" aria-label="dashboard-menu">
                <span class="filter-name">{{ localFilter ? localFilter.name : 'Local'}}</span><mat-icon style="color: #00bcd4">arrow_drop_down</mat-icon>
            </button>
            <mat-menu #localMenu="matMenu">
                <button mat-menu-item (click)="selectLocal(null)">Local</button>
                <button mat-menu-item *ngFor="let local of filteredLocals" (click)="selectLocal(local)">
                    {{local.name}}
                </button>
            </mat-menu>


            <button class="dashboard-menu" mat-button [matMenuTriggerFor]="sectorMenu" aria-label="dashboard-menu">
                <span class="filter-name">{{ sectorFilter ? sectorFilter.name : 'Setor'}}</span><mat-icon style="color: #00bcd4">arrow_drop_down</mat-icon>
            </button>
            <mat-menu #sectorMenu="matMenu">
                <button mat-menu-item (click)="selectSector(null)">Setor</button>
                <button mat-menu-item *ngFor="let sector of filteredSectors" (click)="selectSector(sector)">
                    {{sector.name}}
                </button>
            </mat-menu>


            <button *ngIf="monitor" class="dashboard-menu" mat-button [matMenuTriggerFor]="monitorMenu" aria-label="dashboard-menu">
                <span class="filter-name"> Selecione um monitor </span><mat-icon style="color: #00bcd4">arrow_drop_down</mat-icon>
            </button>
            <mat-menu #monitorMenu="matMenu">
                <button mat-menu-item *ngFor="let monitor of filteredMonitors" (click)="selectMonitor(monitor)">
                  {{monitor.monitorable.code}} - {{monitor.name}} {{monitor.code}}
                </button>
            </mat-menu>
        </div>
        <div style="flex-grow:1"></div>
    </mat-toolbar-row>
</mat-toolbar>
