import { Component, OnInit } from '@angular/core';
import { Card } from '../card';

@Component({
  selector: 'app-temperature-humidity-card',
  templateUrl: './temperature-humidity-card.component.html',
  styleUrls: [
    './temperature-humidity-card.component.sass',
    '../../equipment-card.component.sass'
  ]
})
export class TemperatureHumidityCardComponent extends Card implements OnInit {

  constructor() {
      super();
  }

  ngOnInit(): void {
  }

}
