import { Monitor } from './monitor';

export enum WARNING_TYPE {
    NONE = 0,
    INFORMATION = 1,
    URGENT = 2, //#ff9800
    CRITICAL = 3, //#e62a10
    LIFE_SAFETY = 4, //#e62a10
}

export class Alarm {
    id: number;
    type: WARNING_TYPE;
    startDate: Date;
    endDate: Date;
    message: string;
    status: boolean;
    monitor: Monitor;

    constructor(
        id: number,
        type: WARNING_TYPE = WARNING_TYPE.NONE,
        startDate: any,
        endDate: any,
        message: string,
        status: boolean,
        monitor: Monitor
    ){
        this.id = id;
        this.type = type;
        this.startDate= new Date(startDate);
        this.endDate = new Date(endDate);
        this.message = message;
        this.status = status;
        this.monitor = monitor;
    }
}
