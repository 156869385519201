import { Component, OnInit, Inject} from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AlarmService } from '../../services/alarm.service';
import { NotificationSnackService } from '../../services/notification-snack.service';
import { SettingsService } from '../../services/settings.service';
import { Monitor } from '../../types/monitor';
import { Alarm } from '../../types/alarm';


import * as _moment from 'moment';

const moment = _moment;

export interface AcceptedDialogData {
  monitor: Monitor,
  alarm: Alarm
}

@Component({
  selector: 'app-accepted-dialog',
  templateUrl: './accepted-dialog.component.html',
  styleUrls: ['./accepted-dialog.component.sass']
})
export class AcceptedDialogComponent implements OnInit {

  acceptForm: FormGroup;

  public reasonOptions: Array<{id: string, label: string}>;
  public warningLevels: Array<{
    id: number,
    label: string,
    color: string
  }>

  public monitor: Monitor;
  public alarm: Alarm;
  public loading: boolean = false;

  getFormattedDate(): string {

    const momentDate = moment(this.alarm.startDate);

    momentDate.locale('pt-BR');
    return momentDate.format("ddd DD HH:mm")
  }


  ngOnInit(): void {
    this.fetchSettings();
  }

  constructor(
    private alarmSrv: AlarmService,
    private notificationSrv: NotificationSnackService,
    private settingsSrv: SettingsService,
    public dialogRef: MatDialogRef<AcceptedDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AcceptedDialogData
  ) {
    this.alarm = data.alarm;
    this.monitor = data.monitor;
    this.acceptForm = new FormGroup({
      eventId: new FormControl(this.alarm.id, Validators.required),
      manager: new FormControl('', Validators.required),
      reason: new FormControl('', Validators.required),
      description: new FormControl('', Validators.required)
    });

  }


  getWarningLevelLabel(type: number): string {
    return this.warningLevels.find(level => type == level.id).label;
  }

  fetchSettings(): void {
    this.loading = true;
    this.settingsSrv.getAppConfig().subscribe(
      data => {
        this.reasonOptions = data.reasonOptions;
        this.warningLevels = data.warningLevels;
        this.loading = false;
      },
      err => {
        this.loading = false;
        this.notificationSrv.notify(err.error.error_description || "Um error desconhecido ocorreu no sistema")
      }
    )
  }

  sendEventReason(): void {
    if(this.acceptForm.valid){
      this.loading = true;
      this.alarmSrv.sendEventReason(this.acceptForm.value).subscribe(
        success => {
          this.monitor.deleteAlarm(this.alarm.id)
          this.notificationSrv.notify("Evento reconhecido com sucesso!");
          this.dialogRef.close();
          this.loading = false;
        },
        err => {
          this.notificationSrv.notify(err.error.error_description || "Um error desconhecido ocorreu no sistema")
          this.loading = false;
        },
        () => {
          this.loading = false;
        }
      )
    }
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
