import { Injectable } from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class NotificationSnackService {

    constructor(private _snackBar: MatSnackBar) {}

    public notify(message: string, action: string = "Entendido!", duration: number = 2000) {
        this._snackBar.open(message, action, {
            duration,
        });
    }
}
