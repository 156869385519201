import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Alarm } from '../types/alarm';

@Injectable({
  providedIn: 'root'
})
export class AlarmService {

  constructor(
    private httpClient: HttpClient,
  ) { }

  sendEventReason(body: {
    eventId: number,
    reason: string, //char
    description: string,
    managar: string
  }): Observable<any> {
    return this.httpClient.post(environment.apiUrl + "/event-reason", body, { responseType: 'text' });
  }
}
