import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-offline-card',
  templateUrl: './offline-card.component.html',
  styleUrls: ['./offline-card.component.sass']
})
export class OfflineCardComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
