import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpHeaders, HttpEvent, HttpErrorResponse, HttpResponse} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { NotificationSnackService } from './notification-snack.service';
import { Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class AuthInterceptorService implements HttpInterceptor{
  constructor(
      private authService: AuthService,
      private notificationService: NotificationSnackService,
      private router: Router
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      if (this.authService.getToken()) {
          console.log('token');
          req = this.addToken(req);
      }

      return next.handle(req).pipe(
        map((event: HttpEvent<any>) => {
          return event;
        }),
        catchError((error: HttpErrorResponse) => {
          console.log('error', error);
          this.notificationService.notify('Um erro ocorreu, tente novamente');
          if(error.status === 502){
            this.authService.clearUserData();
            this.router.navigate(['/login'])
          }
          return throwError(error);
        })
      );
  }

  getResourceHeaders(): HttpHeaders {
    return new HttpHeaders({
      'Authorization': 'Bearer ' + this.authService.getToken().access_token
    });
  }

   addToken(request: HttpRequest<any>) {
      return request.clone({
          headers: this.getResourceHeaders()
      });
   }
}
