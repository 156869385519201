<ng-template #titleBar>
  <app-area-dropdown style="flex:1" [brands]="brands" (filterChange)="setSectorFilter($event)"></app-area-dropdown>
  <button mat-raised-button id="tv-mode-button" [style.display]="tvModeOn ? 'block' : 'none'">
    MODO TV
  </button>
</ng-template>

<div class="loading" *ngIf="loading; else dashboard">
  <mat-progress-bar mode="query"></mat-progress-bar>
</div>
<ng-template #dashboard>

  <div class="dashboard">
    <app-equipment-container #equipmentContainer [sectors]="filteredSectors">
    </app-equipment-container>
  </div>
</ng-template>
