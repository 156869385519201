import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { Brand } from '../../types/brand';
import { CacheService } from 'src/app/services/cache.service';
import { UnityType } from '../../types/unity-type';
import { Local } from '../../types/local';
import { Sector } from '../../types/sector';
import { Monitorable } from '../../types/monitorable';

@Component({
    selector: 'app-area-dropdown',
    templateUrl: './area-dropdown.component.html',
    styleUrls: ['./area-dropdown.component.sass']
})
export class AreaDropdownComponent implements  OnChanges{

    private _brands: Array<Brand>;
    brandFilter: Brand;
    unityFilter: UnityType;
    localFilter: Local;
    sectorFilter: Sector;

    filteredBrands: Array<Brand> = [];
    filteredUnities: Array<UnityType> = [];
    filteredLocals: Array<Local> = [];
    filteredSectors: Array<Sector> = [];

    allBrands: Array<Brand> = [];
    allUnities: Array<UnityType> = [];
    allLocals: Array<Local> = [];
    allSectors: Array<Sector> = [];

    search: string = '';

    constructor(
      private cacheSrv: CacheService
    ){}
    @Input()
    set brands(value: Array<Brand>){
        this._brands = value;
    }
    get brands(): Array<Brand>{
        return this._brands;
    }

    @Output()
    filterChange: EventEmitter<Array<Sector>> = new EventEmitter<Array<Sector>>();

    doSearch($event?: string): void{
        this.search = $event || '';
        this.outputFilter();
    }

    outputFilter() {
      this.filterChange.emit(
        this.allBrands.reduce((acc: Array<Sector>, brand: Brand) => {
            brand.unities.forEach((unity: UnityType) => {
              unity.locals.forEach((local: Local) => {
                local.sectors.forEach((sector: Sector) => {
                  const filteredMonitorables = sector.monitorables.map(monitorable => new Monitorable(
                    monitorable.name,
                    monitorable.code,
                    monitorable.description,
                    monitorable.monitors.filter(monitor => {
                      const name: string = monitor.name.toLowerCase();
                      const description: string = monitor.description.toLowerCase();
                      const code: string = monitor.code.toLowerCase();
                      const monitorableCode: string = monitor.monitorable.code.toLowerCase();


                      if(this.brandFilter && (this.brandFilter.id != sector.local.unity.brand.id)) return false;
                      if(this.unityFilter && (this.unityFilter.id != sector.local.unity.id)) return false;
                      if(this.localFilter && (this.localFilter.id != sector.local.id )) return false;
                      if(this.sectorFilter && (this.sectorFilter.id != sector.id )) return false;

                      return [name,description, code, monitorableCode].some(name => name.includes(this.search.toLowerCase()));
                    }),
                    sector

                  ));

                  const filteredSector = new Sector(
                    sector.id,
                    sector.name,
                    filteredMonitorables,
                    sector.local
                  )

                  if(!filteredSector.monitorables.every(monitorable => !monitorable.monitors.length))
                    acc.push(filteredSector);

                });
              })
            })
          return acc;
        }, [])
      );
    }

    selectBrand(brand: Brand){
        this.brandFilter = brand;

        if(this.brandFilter){
          this.filteredBrands = [this.brandFilter];
          this.filteredUnities = this.allUnities.reduce((acc: Array<UnityType>, unity: UnityType): Array<UnityType> => {
            if(this.brandFilter.id == unity.brand.id && !acc.some(accUnity => accUnity.id == unity.id))
              acc.push(unity)

            return acc;
          }, []);
        }
        else {
          this.filteredBrands = this.allBrands;
          this.filteredUnities = this.filteredBrands.reduce((acc: Array<UnityType>, brand: Brand): Array<UnityType> => {
            brand.unities.forEach(unity => {
              if (!acc.some(accUnity => accUnity.id == unity.id))
                acc.push(unity)
            });
            return acc;
          }, new Array<UnityType>());
        }

        this.selectUnity(null);
    }

    selectUnity(unity: UnityType){
        this.unityFilter = unity;

        if(this.unityFilter){
          this.filteredLocals = this.allLocals.reduce((acc: Array<Local>, local: Local): Array<Local> => {
            if(!acc.some(accLocal => accLocal.id == local.id) &&
              this.filteredBrands.some(brand => local.unity.brand.id == brand.id) &&
              local.unity.id == this.unityFilter.id){
              acc.push(local);
            }

            return acc;
          }, [])
        } else {
          this.filteredLocals = this.allLocals.reduce((acc: Array<Local>, local: Local): Array<Local> => {
            if(!acc.some(accLocal => accLocal.id == local.id) &&
              this.filteredBrands.some(brand => local.unity.brand.id == brand.id) &&
              this.filteredUnities.some(unity => local.unity.id == unity.id)){
              acc.push(local);
            }

            return acc;
          }, [])
        }

        this.selectLocal(null);
    }

    selectLocal(local: Local){
        this.localFilter = local;

        if(this.localFilter){
          this.filteredSectors = this.allSectors.reduce((acc: Array<Sector>, sector: Sector): Array<Sector> => {
            if(!acc.some(accSector => accSector.id == sector.id) &&
              this.filteredBrands.some(brand => sector.local.unity.brand.id == brand.id) &&
              this.filteredUnities.some(unity => sector.local.unity.id == unity.id) &&
              sector.local.id == this.localFilter.id){

              acc.push(sector);
            }

            return acc;
          }, [])
        } else {
          this.filteredSectors = this.allSectors.reduce((acc: Array<Sector>, sector: Sector): Array<Sector> => {
            if(!acc.some(accSector => accSector.id == sector.id) &&
              this.filteredBrands.some(brand => sector.local.unity.brand.id == brand.id) &&
              this.filteredUnities.some(unity => sector.local.unity.id == unity.id) &&
              this.filteredLocals.some(local => sector.local.id == local.id)){

              acc.push(sector);
            }

            return acc;
          }, [])
        }

        this.selectSector(null);
    }

    selectSector(sector: Sector){
        this.sectorFilter = sector;
        this.cacheSrv.saveDropdownSelection({
          brand: this.brandFilter,
          unity: this.unityFilter,
          local: this.localFilter,
          sector: this.sectorFilter
        });
        this.doSearch();
        this.outputFilter();
    }


    ngOnChanges(): void {
      this.allBrands = this.brands || [];
      this.allUnities = [];
      this.allLocals = [];
      this.allSectors = [];

      this.allBrands.forEach((brand: Brand) => {
        brand.unities.forEach((unity: UnityType) => {
          unity.locals.forEach((local: Local) => {
            this.allSectors.push(...(local.sectors));
            this.allLocals.push(local);
          })
          this.allUnities.push(unity);
        })
      });

      const cachedDropdownSelection = this.cacheSrv.loadDropdownSelection();
      if(cachedDropdownSelection){
        const {brand, unity, local, sector} = cachedDropdownSelection;
        this.selectBrand(brand);
        this.selectUnity(unity);
        this.selectLocal(local);
        this.selectSector(sector);
      }
      else {
        this.selectBrand(null);
      }
    }
}
