import { Humidity } from './../../types/humidity';
import { Door } from './../../types/door';
import { Temperature } from './../../types/temperature';
import { WebSocketService } from 'src/app/services/web-socket.service';
import { Component, OnInit, ViewChild, ViewContainerRef, AfterViewInit, Output, EventEmitter, TemplateRef} from '@angular/core';
import { Sector } from '../../types/sector';
import { Brand } from '../../types/brand';
import { BrandService } from '../../services/brand.service';
import { map, take} from 'rxjs/operators';
import { AreaDropdownComponent } from './../../components/area-dropdown/area-dropdown.component';
import { WithTitleBar } from '../../types/with-title-bar';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.sass']
})

export class DashboardComponent implements OnInit, AfterViewInit, WithTitleBar {

    @ViewChild('titleBar', { static: true}) titleBar: TemplateRef<any>;

    // @Output()
    // titleBarSet: EventEmitter<AreaDropdownComponent> = new EventEmitter<AreaDropdownComponent>();

    public readonly tvModeOn = false;

    public brands: Array<Brand>;
    
    public filteredSectors: Array<Sector>;

    showFiller = false;

    loading = false;

    constructor(
        private brandSrv: BrandService,
        private webSocketService: WebSocketService
    ) { }

    initializeBrands(brands: Array<Brand>){
        return brands.map(brand => new Brand(
            brand.id,
            brand.name,
            brand.unities
        ))
    }


    ngOnInit(): void {
        this.loading = true;
        this.brandSrv.getBrands().subscribe(
          brands => {
            this.brands = this.initializeBrands(brands);
            this.brands.forEach(brand => {
                brand.unities.forEach(unity => {
                    unity.locals.forEach(local => {
                        local.sectors.forEach(sector => {
                            sector.monitorables.forEach(monitorable => {
                                monitorable.monitors.forEach(monitor => {
                                    // if (!!monitor.type && monitor.type.indexOf('TEMPERATURE') >= 0) {
                                    //     this.webSocketService.connectMonitorVariableValue(monitor.code, 'temperature', msgValue => {
                                    //         if (!!monitor.temperatures &&
                                    //             monitor.temperatures.length > 0) {
                                    //             let first: Temperature = monitor.temperatures[0];
                                    //             let oldValue = first.currentValue;
                                    //             first.currentValue = parseFloat(msgValue.body)
                                    //             first.previousValue = oldValue
                                    //             monitor.lastUpdateTime = new Date()
                                    //             monitor.status = 'ACTIVE'
                                    //         }
                                    //     });
                                    // } else if (!!monitor.type && monitor.type.indexOf('DOOR') >= 0) {
                                    //     this.webSocketService.connectMonitorVariableValue(monitor.code, 'door', msgValue => {
                                    //         if (!!monitor.doors &&
                                    //             monitor.doors.length > 0) {
                                    //             let first: Door = monitor.doors[0];
                                    //             first.currentValue = msgValue.body
                                    //             monitor.lastUpdateTime = new Date()
                                    //             monitor.status = 'ACTIVE'
                                    //         }
                                    //     });
                                    // } else if (!!monitor.type && monitor.type.indexOf('HUMIDITY') >= 0) {
                                    //     this.webSocketService.connectMonitorVariableValue(monitor.code, 'humidity', msgValue => {
                                    //         if (!!monitor.humidities &&
                                    //             monitor.humidities.length > 0) {
                                    //             let first: Humidity = monitor.humidities[0];
                                    //             let oldValue = first.currentValue;
                                    //             first.currentValue = parseFloat(msgValue.body)
                                    //             first.previousValue = oldValue
                                    //             monitor.lastUpdateTime = new Date()
                                    //             monitor.status = 'ACTIVE'
                                    //         }
                                    //     });
                                    // }
                                })
                            })
                        })
                    })
                })
            })
          },
          err => this.brands = [],
          () => this.loading = false
        );
    }

    ngAfterViewInit(): void {
      
    }

    setSectorFilter(sectors: Array<Sector>){
        this.filteredSectors = sectors;
    }



}
