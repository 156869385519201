import { BaseSeries } from './base.series';

export class DoorSeries extends BaseSeries {

  getLegend(){
    return {name: "Porta Aberta"};
  }

  getYAxis() {
    return {
      type: 'value',
      position: 'left',
      offset: 40,
      min: 0,
      max: 1,
      data: [0,1],
      boundaryGap: true,
      axisLabel: {
        formatter: (value) => {
          if(value === 0)
            return 'Aberta';
          return "";
        }
      }
    };
  }

  getSeriesValues(axisValues: string[]) {
    return {
      name: 'Porta Aberta',
      type: 'scatter',
      tooltip: {},
      yAxisIndex: 1,
      data: [
        ['2020-12-25 06:20', 0],
        ['2020-12-25 12:20', 0],
      ],
      animationDelay: function(idx) {
        return idx * 250;
      },
      symbolSize: 20,
    };
  }
}
