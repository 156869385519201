<div class="temp" style="display:flex; align-items: center; justify-content: center; align-items: center;height: 4rem">
    <div class="temp-header" style="display:flex; flex-direction:column; align-items: flex-end; justify-items:center">
        <div *ngIf="monitor.temperatures[0].max">Max {{monitor.temperatures[0].max}}º</div>
        <div *ngIf="monitor.temperatures[0].min">Min {{monitor.temperatures[0].min}}º</div>
    </div>
    <div [class]="'temp-info ' + (monitor.temperatures[0].currentValue > monitor.temperatures[0].max ? 'warning' : '')" style="margin-left: 1rem; display:flex; align-items:center">
        <div> {{monitor.temperatures[0].currentValue}} </div>
        <div class="measure">ºC</div>
        <mat-icon style="font-size: 16px; margin-left: 0.1rem;">{{monitor.temperatures[0].currentValue > monitor.temperatures[0].previousValue ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}}</mat-icon>
    </div>
</div>
<mat-divider [inset]='true' style="margin-bottom: 0.5rem; margin-top: 0.5rem"></mat-divider>
<div [class]="(monitor.temperatures[0].currentValue > monitor.temperatures[0].max ? 'warning' : '') + ' status'" style="font-weight: 500; display:flex; justify-content:center">
    {{monitor.doors[0].currentValue}}
</div>
