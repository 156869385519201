import { BaseSeries } from './base.series';
import { DoorSeries } from './door.series';
import { TemperatureSeries} from './temperature.series';

export class SeriesFactory {
  static createSeries(series: BaseSeries){
    switch(series.type){
      case "temperature":
        return new TemperatureSeries(series);
      case "door":
        return new DoorSeries(series);
      default:
        throw new Error("This series type is not suppored");
    }
  }
}
