import { Component,  Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { CacheService } from 'src/app/services/cache.service';
import { Brand } from '../../types/brand';
import { UnityType } from '../../types/unity-type';
import { Local } from '../../types/local';
import { Sector } from '../../types/sector';
import { Monitorable } from '../../types/monitorable';
import { Monitor } from '../../types/monitor';
import { MonitorService } from '../../services/monitor.service';

@Component({
    selector: 'app-monitor-selection-dropdown',
    templateUrl: './monitor-selection-dropdown.component.html',
    styleUrls: ['./monitor-selection-dropdown.component.sass']
})
export class MonitorSelectionDropdownComponent implements OnChanges{

    private _brands: Array<Brand>;
    brandFilter: Brand;
    unityFilter: UnityType;
    localFilter: Local;
    sectorFilter: Sector;
    monitorFilter: Monitor;

    filteredBrands: Array<Brand> = [];
    filteredUnities: Array<UnityType> = [];
    filteredLocals: Array<Local> = [];
    filteredSectors: Array<Sector> = [];
    filteredMonitors: Array<Monitor> = [];

    allBrands: Array<Brand> = [];
    allUnities: Array<UnityType> = [];
    allLocals: Array<Local> = [];
    allSectors: Array<Sector> = [];
    allMonitors: Array<Monitor> = [];


    constructor(
      public monitorSrv: MonitorService,
      private cacheSrv: CacheService
    ){
    }
    @Input()
    set brands(value: Array<Brand>){
        this._brands = value;
    }
    get brands(): Array<Brand>{
        return this._brands;
    }

    @Input()
    monitor: Monitor;

    @Output()
    filterChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

    selectBrand(brand: Brand, hasHumanInteraction: boolean = true){
        this.brandFilter = brand;

        if(this.brandFilter){
          this.filteredBrands = [this.brandFilter];
          this.filteredUnities = this.allUnities.reduce((acc: Array<UnityType>, unity: UnityType): Array<UnityType> => {
            if(this.brandFilter.id == unity.brand.id && !acc.some(accUnity => accUnity.id == unity.id))
              acc.push(unity)

            return acc;
          }, []);
        }
        else {
          this.filteredBrands = this.allBrands;
          this.filteredUnities = this.filteredBrands.reduce((acc: Array<UnityType>, brand: Brand): Array<UnityType> => {
            brand.unities.forEach(unity => {
              if (!acc.some(accUnity => accUnity.id == unity.id))
                acc.push(unity)
            });
            return acc;
          }, new Array<UnityType>());
        }

        this.selectUnity(null, hasHumanInteraction);
    }

    selectUnity(unity: UnityType, hasHumanInteraction: boolean = true){
        this.unityFilter = unity;

        if(this.unityFilter){
          this.filteredLocals = this.allLocals.reduce((acc: Array<Local>, local: Local): Array<Local> => {
            if(!acc.some(accLocal => accLocal.id == local.id) &&
              this.filteredBrands.some(brand => local.unity.brand.id == brand.id) &&
              local.unity.id == this.unityFilter.id){
              acc.push(local);
            }

            return acc;
          }, [])
        } else {
          this.filteredLocals = this.allLocals.reduce((acc: Array<Local>, local: Local): Array<Local> => {
            if(!acc.some(accLocal => accLocal.id == local.id) &&
              this.filteredBrands.some(brand => local.unity.brand.id == brand.id) &&
              this.filteredUnities.some(unity => local.unity.id == unity.id)){
              acc.push(local);
            }

            return acc;
          }, [])
        }

        this.selectLocal(null, hasHumanInteraction);
    }

    selectLocal(local: Local, hasHumanInteraction: boolean = true){
        this.localFilter = local;

        if(this.localFilter){
          this.filteredSectors = this.allSectors.reduce((acc: Array<Sector>, sector: Sector): Array<Sector> => {
            if(!acc.some(accSector => accSector.id == sector.id) &&
              this.filteredBrands.some(brand => sector.local.unity.brand.id == brand.id) &&
              this.filteredUnities.some(unity => sector.local.unity.id == unity.id) &&
              sector.local.id == this.localFilter.id){

              acc.push(sector);
            }

            return acc;
          }, [])
        } else {
          this.filteredSectors = this.allSectors.reduce((acc: Array<Sector>, sector: Sector): Array<Sector> => {
            if(!acc.some(accSector => accSector.id == sector.id) &&
              this.filteredBrands.some(brand => sector.local.unity.brand.id == brand.id) &&
              this.filteredUnities.some(unity => sector.local.unity.id == unity.id) &&
              this.filteredLocals.some(local => sector.local.id == local.id)){

              acc.push(sector);
            }

            return acc;
          }, [])
        }

        this.selectSector(null, hasHumanInteraction);
    }

    selectSector(sector: Sector, hasHumanInteraction: boolean = true){

        this.sectorFilter = sector;

        if(hasHumanInteraction){
          this.filterChanged.emit(true);
        }

        this.cacheSrv.saveDropdownSelection({
          brand: this.brandFilter,
          unity: this.unityFilter,
          local: this.localFilter,
          sector: this.sectorFilter
        });

        if(this.sectorFilter){
          this.filteredMonitors = this.allMonitors.reduce((acc: Array<Monitor>, monitor: Monitor): Array<Monitor> => {
            if(!acc.some(accMonitor => accMonitor.code == monitor.code) &&
              this.filteredBrands.some(brand => monitor.monitorable.sector.local.unity.brand.id == brand.id) &&
              this.filteredUnities.some(unity => monitor.monitorable.sector.local.unity.id == unity.id) &&
              this.filteredLocals.some(local => monitor.monitorable.sector.local.id == local.id) &&
              monitor.monitorable.sector.id == this.sectorFilter.id){

              acc.push(monitor);
            }

            return acc;
          }, []);
        } else {
          this.filteredMonitors= this.allMonitors.reduce((acc: Array<Monitor>, monitor: Monitor): Array<Monitor> => {
            if(!acc.some(accMonitor => accMonitor.code == monitor.code) &&
              this.filteredBrands.some(brand => monitor.monitorable.sector.local.unity.brand.id == brand.id) &&
              this.filteredUnities.some(unity => monitor.monitorable.sector.local.unity.id == unity.id) &&
              this.filteredLocals.some(local => monitor.monitorable.sector.local.id == local.id) &&
              this.filteredSectors.some(sector => monitor.monitorable.sector.id == sector.id)) {

              acc.push(monitor);
            }

            return acc;
          }, [])
        }

    }

    selectMonitor(monitor: Monitor){
      this.monitorSrv.goToCardDetails(monitor);
      this.filterChanged.emit(false);
    }


    ngOnChanges(): void {
      this.allBrands = this.brands || [];
      this.allUnities = [];
      this.allLocals = [];
      this.allSectors = [];

      this.allBrands.forEach((brand: Brand) => {
        brand.unities.forEach((unity: UnityType) => {
          unity.locals.forEach((local: Local) => {
            this.allSectors.push(...(local.sectors));
            this.allSectors.forEach(sector => {
              sector.monitorables.forEach((monitorable: Monitorable) => {
                this.allMonitors.push(...(monitorable.monitors));
              })
            });
            this.allLocals.push(local);
          })
          this.allUnities.push(unity);
        })
      });

      const cachedDropdownSelection = this.cacheSrv.loadDropdownSelection();
      if(cachedDropdownSelection){
        const {brand, unity, local, sector} = cachedDropdownSelection;
        this.selectBrand(brand, false);
        this.selectUnity(unity, false);
        this.selectLocal(local, false);
        this.selectSector(sector, false);
      }
      else {
        this.selectBrand(null, false);
      }
    }
}
