import { Injectable } from '@angular/core';
import { Brand } from '../types/brand';
import { Local } from '../types/local';
import { BehaviorSubject, Observable} from 'rxjs';
import { publishReplay, refCount, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { HttpClient} from '@angular/common/http';


@Injectable({
    providedIn: 'root'
})
export class BrandService {

    cachedBrands: Observable<any>;

    constructor(
      private httpClient: HttpClient
    ) {
    }


    getBrands(): Observable<any> {
      if(!this.cachedBrands){
        this.cachedBrands = this.httpClient.get(environment.apiUrl + '/brands').pipe(
          publishReplay(1),
          refCount()
        )
      }

      return this.cachedBrands;
    }

}

