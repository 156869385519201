import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { UserCredentials } from '../types/user-credentials';
import { SessionStorageService } from '../services/session-storage.service';
import { Token } from '../types/token';
import { Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class AuthService {

    private readonly clientId: string = environment.oAuthClientId;;
    private readonly clientSecret: string = environment.oAuthClientSecret;;
    private readonly oAuthUrl: string = environment.oAuthUrl;;
    private readonly oAuthTokenPath: string = environment.oAuthTokenPath;;
    private readonly tokenStorageName: string = 'token';

    public user: string;

    constructor(
        private http: HttpClient,
        private sessionStorageService: SessionStorageService,
        private router: Router

    ) {}


    login(userCredentials: UserCredentials){
        return this.http.post(`${this.oAuthUrl}${this.oAuthTokenPath}`, this.getParams(userCredentials), {headers: this.getHeaders()})
        .pipe(
            map((token: Token) => {
                this.setToken(token);
                this.setUser(userCredentials.userName);
                return true;
            })
        );
    }

    getUser(): string {
        return JSON.parse(this.sessionStorageService.getItem('user')) as string;
    }


    setUser(user: string){
        this.sessionStorageService.setItem('user', JSON.stringify(user));
    }

    isLoggedIn(): boolean {
        const token = this.getToken();
        if(token && token.access_token.length) return true;
        else return false;
    }

    setToken(token: Token): void{
        this.sessionStorageService.setItem(this.tokenStorageName, JSON.stringify(token));
    }

    getToken(): Token {
        return JSON.parse(this.sessionStorageService.getItem(this.tokenStorageName)) as Token;
    }


    clearUserData(){
        ['user','token'].forEach(item => this.sessionStorageService.removeItem(item));
    }

    getParams(userCredentials: UserCredentials): HttpParams {
        return new HttpParams()
        .set('username', userCredentials.userName)
        .set('password', userCredentials.password)
        .set('grant_type', 'password');
    }

    getHeaders(): HttpHeaders {
        return new HttpHeaders({
            'Authorization': 'Basic ' + btoa(`${this.clientId}:${this.clientSecret}`),
            'Content-type': 'application/x-www-form-urlencoded'
        });
    }

    logout(): void {
      this.sessionStorageService.removeItem('token');
      this.sessionStorageService.removeItem('user');
      (window as any).location.reload();
    }

}
