import { Component, OnInit, Input } from '@angular/core';
import { Statistic } from './statistic';

@Component({
  selector: 'app-equipment-statistic',
  templateUrl: './equipment-statistic.component.html',
  styleUrls: ['./equipment-statistic.component.sass']
})
export class EquipmentStatisticComponent implements OnInit {

  @Input()
  statistic: Statistic;

  constructor() { }

  ngOnInit(): void {
  }

}
