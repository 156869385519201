import { Component, OnInit } from '@angular/core';
import { Card } from '../card'

@Component({
  selector: 'app-temperature-door-card',
  templateUrl: './temperature-door-card.component.html',
  styleUrls: [
      './temperature-door-card.component.sass',
      '../../equipment-card.component.sass'
  ]
})
export class TemperatureDoorCardComponent extends Card implements OnInit {

  constructor() {
      super();
  }

  ngOnInit(): void {
  }

}
