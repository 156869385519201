import { Pipe, PipeTransform } from '@angular/core';
import { NavigationEnd } from '@angular/router';

@Pipe({
  name: 'URLPathName'
})
export class URLPathNamePipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): string {
    const segments = value.split("/");
    return segments[segments.length-1];
  }

}
