import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';
import * as SockJS from 'sockjs-client';
import * as Stomp from 'stompjs';

class WebSocket {

  constructor(
    public topic: string,
    public id: string,
    private onMessage: Function,
    private stompClient: any) {
      this.subscribe()
  }

  private subscribe() {
    //console.log('CONNECTING TOPIC', this.topic)
    this.stompClient.subscribe(this.topic, event => {
      this.onMessage(event)
    }, {
      id: this.id
    })
    //console.log('TOPIC CONNECTED', this.topic)
  }

}

@Injectable({
  providedIn: 'root'
})
export class WebSocketService {

  private stompClient: any;
  private webSockets: Array<WebSocket>;
  private ws: any;

  constructor() {
    this.ws = new SockJS(environment.webSocketEndPoint);
    this.stompClient = Stomp.over(this.ws);
    this.webSockets = new Array<WebSocket>();
    this.connect();
  }

  private connect() {
    //console.log('INICIANDO CONEXAO WS')
    this.stompClient.connect({}, frame => {
      //console.log('WS CONECTADO COM SUCESSO')
    }, err => {
      console.error('ERRO NA CONEXAO WS', err)
      this.stompClient = Stomp.over(this.ws);
      this.connect();
    })
  }

  private sleep(ms: number): Promise<any> {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  public async connectTopic(id: string, topic: string, onMessage: Function, callbackError?: Function) {
    while (!this.stompClient.connected) {
      await this.sleep(2000);
      this.connect();
    }
    let webSocket: WebSocket = new WebSocket(id, topic, onMessage, this.stompClient)
    this.webSockets.push(webSocket)
  }

  public async disconnectTopic(id: string) {
    this.stompClient.unsubscribe(id)
    this.webSockets = this.webSockets.filter(ws => ws.id != id)
    //console.log('TOPIC DISCONECTED. ID:', id)
  }

  public async connectMonitorVariableValue(monitorCode: string, variableType: string, onMessage: Function, callbackError?: Function) {
    let topic = '/topic/' + monitorCode + '/' + variableType
    if (this.webSockets.some(ws => ws.topic == topic))
      this.disconnectTopic(topic);
    this.connectTopic(topic, topic, onMessage, callbackError)
  }

  public async disconectMonitorVariableValue(monitorCode: string, variableType: string) {
    let topic = '/topic/' + monitorCode + '/' + variableType
    this.disconnectTopic(topic);
  }

}
