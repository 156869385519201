import { UnityType } from './unity-type';

export class Brand {
    id: number;
    name: string;
    unities: Array<UnityType>;

    initializeUnities(unities: Array<UnityType>){
        return unities.map(unity => new UnityType(
            unity.name,
            unity.id,
            unity.locals,
            this
        ));
    }

    constructor(
        id: number,
        name: string,
        unities: Array<UnityType>
    ){
        this.id = id;
        this.name = name;
        this.unities = this.initializeUnities(unities);
    }
}
