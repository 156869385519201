import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SessionStorageService { //CREATE WINDOW WRAPER TO POLYMORPHISM

  constructor() { }

  getWindow(): any{
      return window as any;
  }
  setItem(itemName: string, itemValue: any): void {
      this.getWindow().sessionStorage.setItem(itemName, itemValue);
  }

  getItem(itemName: string): any {
      return this.getWindow().sessionStorage.getItem(itemName);
  }

  removeItem(itemName: string): void{
      return this.getWindow().sessionStorage.removeItem(itemName);
  }
}


